import React from "react";
import { IntlProvider } from "react-intl";
import flatten from "flat";

import { Provider } from "./context";

import fr from "../lang/fr.json";
import en from "../lang/en.json";

const messages = {
  fr: fr,
  en: en,
};

const WrapPageElement = ({ element, props }) => {
  const locale = props.pageContext.locale;

  return (
    <IntlProvider
      locale={locale}
      defaultLocale="fr"
      messages={locale && flatten(messages[locale])}
    >
      <Provider value={props}>{element}</Provider>
    </IntlProvider>
  );
};

export default WrapPageElement;
