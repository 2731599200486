import React from "react";
import { Provider } from "mobx-react";

import SearchStore from "../models/search-model";

const WrapRootElement = ({ element }) => (
  <Provider store={SearchStore}>{element}</Provider>
);

export default WrapRootElement;
