import { observable } from "mobx";

class SearchModel {
  @observable searchQuery = {
    type: "hotel",
    booking_id: "",
  };
  @observable focusedInput = null;
  @observable startDate = null;
  @observable endDate = null;
  @observable couponCode = "";
}

const SearchStore = new SearchModel();
export default SearchStore;
